import React, { memo } from "react";

import isEqual from "react-fast-compare";
import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";

import PATHS from "PATHS";

import styles from "./style.module.scss";

const LIST = [
	{
		path: PATHS.cabinet.trends.database.path,
		label: "Trending Now"
	},
	{
		path: PATHS.cabinet.trends.reports.path,
		label: "Weekly Digest"
	}
];

const TrendsNav: React.FC = () => {
	return (
		<Box className={styles.container}>
			{LIST.map(({ path, label }) => (
				<NavLink className={styles.item} key={path} to={path}>
					{label}
				</NavLink>
			))}
		</Box>
	);
};

export default memo(TrendsNav, isEqual);
