import PATHS from "PATHS";
import { PERMISSIONS } from "app/enum/permissions";
import config from "app/config";
import {
	// IconSolution,
	IconAudienceBuilding,
	// IconCampaignManage,
	IconCampaigns,
	IconSupport,
	IconSettings,
	IconUsers,
	IconInfoDashboard,
	IconEmployees,
	IconHelpCenter
	// IconWeb3
	// IconDataVendor
} from "app/components/includes/aside/icons";
import { IMenuItem } from "app/components/includes/aside/types";
import { setMenuItemId } from "app/components/includes/aside/helper_ts";

const _MENU_ITEMS: IMenuItem[] = [
	// {
	// 	icon: <IconDataVendor />,
	// 	caption: "Data vendors",
	// 	to: PATHS.cabinet.dataVendors
	// },
	{
		icon: <IconCampaigns />,
		caption: config.pageTitles.trendsTracker,
		to: PATHS.cabinet.trends.database.path,
		matchPaths: [
			PATHS.cabinet.trends.reports.path,
			PATHS.cabinet.trends.database.path
		]
	},
	{
		icon: <IconAudienceBuilding />,
		caption: config.pageTitles.audienceBuilder,
		to: PATHS.cabinet.tracking.path
	},

	// {
	// 	icon: <IconSolution />,
	// 	caption: "Solutions",
	// 	subItems: [
	// 		{
	// 			icon: <IconCampaignManage />,
	// 			caption: "Data activation",
	// 			to: PATHS.cabinet.campaigns,
	// 			subItems: [
	// 				{
	// 					icon: <IconCampaigns />,
	// 					caption: "Campaigns",
	// 					to: PATHS.cabinet.campaigns,
	// 					permission: PERMISSIONS.account_campains
	// 				},
	// 			]
	// 		}
	// 	]
	// },
	{
		icon: <IconUsers />,
		caption: config.pageTitles.users,
		to: PATHS.adminPanel.users.path,
		permission: PERMISSIONS.admin_users
	},
	{
		icon: <IconEmployees />,
		caption: config.pageTitles.employees,
		to: PATHS.adminPanel.employees,
		permission: PERMISSIONS.admin_sheets
	},
	{
		icon: <IconSettings />,
		caption: config.pageTitles.appSettings,
		to: PATHS.adminPanel.settings,
		permission: PERMISSIONS.admin_general_settings
	},
	{
		icon: <IconInfoDashboard />,
		caption: config.pageTitles.infoDashboard,
		to: PATHS.adminPanel.infoDashboard,
		permission: PERMISSIONS.admin_statistic
	},
	{
		icon: <IconSupport />,
		caption: "Support",
		action: "support",
		link: ""
	},
	{
		icon: <IconHelpCenter />,
		caption: "Help center",
		action: "support",
		link: config.helpCenter
	}
];

// const _MENU_ITEMS = [
// 	{
// 		icon: <InfoDashboardIcon />,
// 		caption: "Solutions3232323",
// 		subItems: [
// 			{
// 				icon: <InfoDashboardIcon />,
// 				caption: "Info dashboard",
// 				to: PATHS.adminPanel.infoDashboard,
// 				permission: PERMISSIONS.admin_statistic
// 			},
// 			{
// 				icon: <Campaigns />,
// 				caption: "Campaign management123123",
// 				subItems: [
// 					{
// 						icon: <UsersIcon />,
// 						caption: "Users",
// 						to: PATHS.adminPanel.users.path,
// 						permission: PERMISSIONS.admin_users
// 					},
// 					{
// 						icon: <EmployeesIcon />,
// 						caption: "Employees",
// 						to: PATHS.adminPanel.employees,
// 						permission: PERMISSIONS.admin_sheets
// 					},
// 				]
// 			}
// 		]
// 	},

// 	{
// 		icon: <UsersIcon />,
// 		caption: "Users",
// 		to: PATHS.adminPanel.users.path,
// 		permission: PERMISSIONS.admin_users
// 	},
// 	{
// 		icon: <EmployeesIcon />,
// 		caption: "Employees",
// 		to: PATHS.adminPanel.employees,
// 		permission: PERMISSIONS.admin_sheets
// 	},
// 	{
// 		icon: <SettingsIcon />,
// 		caption: "APP settings",
// 		to: PATHS.adminPanel.settings,
// 		permission: PERMISSIONS.admin_general_settings
// 	},
// 	{
// 		icon: <CategoriesIcon />,
// 		caption: "Audience Categories",
// 		to: PATHS.adminPanel.categoriesAudience,
// 		permission: PERMISSIONS.admin_general_settings
// 	},
// 	{
// 		icon: <InfoDashboardIcon />,
// 		caption: "Info dashboard",
// 		to: PATHS.adminPanel.infoDashboard,
// 		permission: PERMISSIONS.admin_statistic
// 	}

// 	// {
// 	// 	icon: <ContentGeneration />,
// 	// 	caption: "Content generation",
// 	// 	to: PATHS.cabinet.contentGenetarion,
// 	// },
// ];

const _BOTTOM_ITEMS: [] = [];

export const MENU_ITEMS = setMenuItemId(_MENU_ITEMS);
export const BOTTOM_ITEMS = setMenuItemId(_BOTTOM_ITEMS);
