const DEFAULT_INCLUDES = [
	{
		title: "Weekly trend reports",
		text: "Yes"
	},
	{
		title: "Access to trend database",
		text: "Yes"
	},
	{
		title: "Number of contacts for tracking",
		text: "Up to 1000 contacts"
	},
	{
		title: "Triggers",
		text: ["Keywords mention", "New Job Opening"]
	},
	{
		title: "Email-based support",
		text: "Yes"
	}
];

const LIGHT_INCLUDES = [
	...DEFAULT_INCLUDES,
	{
		title: "Integrations",
		text: "None",
		disabled: true
	}
];

const START_INCLUDES = [...LIGHT_INCLUDES];
START_INCLUDES.splice(2, 1, {
	title: "Number of contacts for tracking",
	text: "From 1001 to 5000"
});

const BUSINESS_INCLUDES = [
	...START_INCLUDES,
	{
		title: "Dedicated Support",
		text: "Yes"
	}
];

BUSINESS_INCLUDES.splice(2, 1, {
	title: "Number of contacts for tracking",
	text: "From 5001 to 20000"
});

export const PRICES_INCLUDES = ([
	LIGHT_INCLUDES,
	START_INCLUDES,
	BUSINESS_INCLUDES
] as undefined) as { disabled?: boolean; title: string; text: string }[][];
