import PATHS from "PATHS";
import { isDev } from "app/utils/isDev";

const onBoardingKey = "onBoarding";

const getOnboardingPass = (): boolean | undefined => {
	const isDevHost = "newtest.signum.website";
	if (isDevHost === window.location.host || isDev) {
		const onBoarding = localStorage.getItem(onBoardingKey);

		if (onBoarding === "true") {
			return true;
		} else if (onBoarding === "false") {
			return false;
		}
	}
};

const config = {
	onBoarding: getOnboardingPass(),

	ui: {
		pagination: {
			default: {
				total: 0,
				limit: 10,
				page: 1
			},
			size: 10,
			sizeGrid: 12
		},
		tableSize: [10, 30, 50, 100],
		tableSizeGrid: [12, 24, 60, 120],
		dateReport: "last_7d"
	},
	minAge: 13,
	maxAge: 65,
	roleDefault: 4,
	defaultPage: {
		link: PATHS.cabinet.trends.database.path,
		text: "Database",
		textRedirect: "Go to Database"
	},
	showTrackingModal: "showTrackingModal",
	artemLinkCalendly: "https://calendly.com/gladkikh/",
	privacyPolicy: "https://signum.ai/privacy-policy/",
	termsOfUse: "https://signum.ai/terms-of-use/",
	helpCenter: "https://help.signum.ai/",

	pageTitles: {
		trendsTracker: "Trends Tracker",
		audienceBuilder: "Audience Builder",
		users: "Users",
		employees: "Employees",
		appSettings: "APP settings",
		infoDashboard: "Info dashboard"
	}
};

config.ui.pagination.default.limit = config.ui.pagination.size;

export const changeOnBoarding = (value: boolean) => {
	localStorage.setItem(onBoardingKey, String(value));
	config.onBoarding = getOnboardingPass();
};

export default config;
