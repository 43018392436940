import { memo, useState } from "react";

import isEqual from "react-fast-compare";
import { useDispatch } from "react-redux";

import Modal from "app/components/modal";
import Typography from "app/uikit-V2/Typography";
import actions from "app/store/user/actions";

import styles from "./style.module.scss";
import img1 from "./assets/img1.svg";
import img3 from "./assets/img2.svg";
import img4 from "./assets/img3.svg";
import img5 from "./assets/img4.webp";

const items = [
	{
		img: img1,
		title: "Hello! And Welcome to Signum.AI 👋",
		text:
			"Discover marketing trends, trending memes, and build hyper-targeted audiences with AI tools designed to transform you into an AI-driven marketer!",
		btnText: "Let's go"
	},
	{
		img: img3,
		title: "Discover What’s Trending Now",
		text: (
			<>
				Visit the <strong>Trending Now tab</strong> to explore the latest rising
				trends and stay ahead of the curve
			</>
		),
		btnText: "Next"
	},
	{
		img: img4,
		title: "Build Hyper-Segmented Audiences",
		text: (
			<>
				Visit the <strong>Audience Builder tab</strong> to upload your list and
				identify key segments based on intent signals
			</>
		),
		btnText: "Next"
	},
	{
		img: img5,
		title: "Contact Us With Any Questions 🙏",
		text: (
			<>
				We hope our tool will be useful for you, and if you have any questions, you
				can ask them on the <strong>Support tab</strong>
			</>
		),
		btnText: "Got it"
	}
];

interface Props {
	open: boolean;
	onClose: () => void;
}

const FirstLoginModal: React.FC<Props> = ({ open, onClose }) => {
	const [step, setStep] = useState(0);
	const dispatch = useDispatch();

	const { img, title, text, btnText } = items[step];

	const btnAction = () => {
		if (items.length - 1 <= step) {
			dispatch(actions.firstLoginPassed());
			onClose();
		} else {
			setStep(state => ++state);
		}
	};

	return (
		<Modal
			size="auto"
			open={open}
			onClose={() => {}}
			footer
			firstBtnHide
			footerJustify="center"
			secondBtnAction={btnAction}
			secondBtnText={btnText}
		>
			<div className={styles.container}>
				<img className={styles.img} src={img} alt="" />
				<Typography variant="h4" marginBottom="14px">
					{title}
				</Typography>
				<Typography>{text}</Typography>
			</div>
		</Modal>
	);
};

export default memo(FirstLoginModal, isEqual);
