import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PageTitle from "app/components/PageTitle";
import { configSelect } from "app/store/select";
import actions from "app/store/trendsDatabase/actions";
import Footer from "app/uikit/table/footer";
import { IPagination } from "app/types";
import { useIsAdmin } from "app/hooks/useIsAdmin";
import { asyncConfirm } from "app/components/modal/asyncConfirm";
import { ITrendDatabaseItem } from "app/store/trendsDatabase/type";
import Loader from "app/components/loaderV2";
import PATHS from "PATHS";
import PriceLayout from "app/components/Price/PriceLayout";
import { useSubscribe } from "app/hooks/useSubscribe";
import config from "app/config";

import TrendsNav from "../components/nav";

import TrendsDataBaseFilter from "./components/Filter";
import TrendsDatabaseContent from "./components/ContentGrid";

const Trends: React.FC = () => {
	const { trends, isLoading, pagination } = useSelector(
		configSelect.trendsDatabase
	);
	const subscription = useSubscribe();
	const { isAdmin } = useIsAdmin();
	const history = useHistory();
	const dispatch = useDispatch();
	const [renderFilter, setRenderFilter] = useState<boolean>();

	const onPaginationChange = useCallback(
		(data: IPagination) => {
			dispatch(actions.fetchPagination(data));
		},
		[dispatch]
	);

	const moveToCreateTrend = useCallback(() => {
		history.push(PATHS.adminPanel.trends.database.create);
	}, [history]);

	const onDelete = async (data: ITrendDatabaseItem) => {
		const agree = await asyncConfirm({
			title: `Delete ${data?.name}`,
			message: "Are you sure you want to delete this trend?"
		});
		if (!agree) return;
		dispatch(actions.fetchDeleteTrendDatabase(data.id));
	};

	useEffect(() => {
		if (trends.success !== undefined && renderFilter === undefined) {
			setRenderFilter(!!trends.count);
		}
	}, [trends.count, trends.success, renderFilter]);

	return (
		<>
			<PageTitle
				title={config.pageTitles.trendsTracker}
				showButton={isAdmin}
				buttonText="Create a trend"
				buttonAction={moveToCreateTrend}
			/>
			<TrendsNav />
			<Loader loading={!trends.success}>
				{renderFilter && subscription.isActive && <TrendsDataBaseFilter />}
				<PriceLayout>
					<TrendsDatabaseContent
						data={trends}
						isLoading={isLoading.trends}
						onDelete={onDelete}
						showOnlyFirstLine={!subscription.isActive}
					/>
				</PriceLayout>

				{!!trends.count && (subscription.isActive || isAdmin) && (
					<Footer
						pagination={pagination.trends}
						paginationChange={onPaginationChange}
					/>
				)}
			</Loader>
		</>
	);
};

export default Trends;
