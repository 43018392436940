import React, { useState } from "react";

import Modal from "app/components/modal";
import Typography from "app/uikit-V2/Typography";

import { renderModal } from "../modalInfo";

import styles from "./style.module.scss";

const NoInternetConnection: React.FC = () => {
	const [open, setOpen] = useState(true);

	const onClose = () => setOpen(false);

	if (open) {
		return (
			<Modal
				onClose={onClose}
				open={open}
				footer
				secondBtnText="Ok"
				secondBtnAction={onClose}
				firstBtnHide
				footerJustify="center"
			>
				<Typography className={styles.modal__text} variant="h6">
					Internet connection issues. Disable VPN or refresh the page. If it
					persists, contact us at <a href="mailto:hi@signum.ai">hi@signum.ai</a>
				</Typography>
			</Modal>
		);
	}
};

export const showModalNotInternet = () => renderModal(<NoInternetConnection />);

export default NoInternetConnection;
